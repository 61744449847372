import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { paths } from 'src/routes/paths';
import { Button } from '@mui/material';
import {
  createRegistration,
  updateRegistration,
} from 'src/api/services/registration-list.services';
// import { useAuthContext } from 'src/auth/hooks';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { RHFMultiCustomCheckbox } from 'src/components/hook-form/rhf-custom-checkbox';
import RHFRadioCustomGroup from 'src/components/hook-form/rhf-custom-radio-group';
import { useLocales } from 'src/locales';
import {
  currentUser,
  detail_of_various_service_work_options,
  getErrorMg,
  institution_types_options,
  jilla_places_options,
  scope_of_the_organization_options,
} from 'src/utils/utils';
import * as Yup from 'yup';

const RegisterSchema = Yup.object().shape({
  name_of_the_organization: Yup.string().required('Organization name required'),
  what_type_of_organization: Yup.string().required('This field is required!'),
  detail_of_various_service_work: Yup.array().min(1, 'Choose at least one option'),
  // more_details_about_service: Yup.string().required('This field is required!'),
  full_address_of_the_institution: Yup.string().required('This field is required!'),
  organization_contact: Yup.string().required('This field is required!'),
  email_of_organization: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  name_of_the_trustee_administrator_of_the_institution:
    Yup.string().required('This field is required!'),
  their_mobile_number: Yup.string()
    // .required('Enter Mobile Number')
    .matches(/^(|[6-9][0-9]{9})$/, 'Please enter a valid Mobile Number.')
    .typeError('Please enter a valid Mobile Number.'),
  scope_of_the_organization: Yup.string().required('This field is required!'),
  sub_scope_of_the_organization: Yup.object()
    // .test('required', 'This field is required!', (value, { parent }) => {
    //   if (['all_india'].includes(parent.scope_of_the_organization)) {
    //     return value && (value?.value !== '' || value?.value !== null);
    //   }
    //   return true;
    // })
    .test(
      'required',
      'This field is required!',
      (value) => value && (value?.value !== '' || value?.value !== null)
    )
    .nullable(),
});

const defaultValues = {
  name_of_the_organization: '',
  what_type_of_organization: '',
  detail_of_various_service_work: [],
  more_details_about_service: '',
  full_address_of_the_institution: '',
  organization_contact: '',
  email_of_organization: '',
  name_of_the_trustee_administrator_of_the_institution: '',
  their_mobile_number: '',
  scope_of_the_organization: '',
  sub_scope_of_the_organization: null,
};

export default function NewRegistrationForm() {
  const location = useLocation();
  const state = location.state || {};
  const { registrationData } = state;
  const navigate = useNavigate();

  // const { newRegister, authenticated } = useAuthContext();

  const locales = useLocales();
  const { t } = useLocales();

  useEffect(() => {
    if (locales.currentLang.value === 'en') {
      locales.onChangeLang('guj');
    }
  }, []);

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  useEffect(() => {
    if (registrationData) {
      const subScopeOption = jilla_places_options.find(
        (option) => option?.value === registrationData?.sub_scope_of_the_organization
      );

      const formattedData = {
        ...registrationData,
        sub_scope_of_the_organization: subScopeOption || null,
        detail_of_various_service_work:
          registrationData?.detail_of_various_service_work?.split(',') || [],
      };

      reset(formattedData);
    }
  }, [registrationData]);

  // const onSubmit = handleSubmit(async (data) => {
  //   console.log('data: ', data);

  //   try {
  //     await newRegister?.({
  //       payload: {
  //         data: {
  //           ...data,
  //           detail_of_various_service_work: data?.detail_of_various_service_work.join(','),
  //         },
  //       },
  //     });

  //     enqueueSnackbar('Registration Successfully');
  //     reset();
  //   } catch (error) {
  //     console.error(error);
  //     // reset();
  //     getErrorMg('Invalid', { variant: 'error' });
  //   }
  // });

  // const onSubmit = handleSubmit((data) => {

  //   createRegistration({
  //     // data: {
  //     ...data,
  //     detail_of_various_service_work: data?.detail_of_various_service_work.join(','),
  //     sub_scope_of_the_organization: data?.sub_scope_of_the_organization?.value,
  //     // },
  //   })

  //     .then((r) => {
  //       enqueueSnackbar('Registration Successfully');
  //       reset();
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       // reset();
  //       getErrorMg('Invalid', { variant: 'error' });
  //     });
  // });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const formattedData = {
        ...data,
        detail_of_various_service_work: data?.detail_of_various_service_work.join(','),
        sub_scope_of_the_organization: data?.sub_scope_of_the_organization?.value,
      };

      if (registrationData) {
        // Update existing registration

        await updateRegistration(registrationData.id, formattedData);
        enqueueSnackbar('Registration Updated Successfully', { variant: 'success' });
        navigate(paths.hssfPaths.registration.list);
      } else {
        // Create new registration
        await createRegistration(formattedData);
        enqueueSnackbar('Registration Created Successfully', { variant: 'success' });
      }

      reset();
    } catch (error) {
      console.error('Registration error:', error);
      enqueueSnackbar('Registration Failed', { variant: 'error' });
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit} sx={{ pb: '80px' }}>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <RHFTextField
            name="name_of_the_organization"
            label={`${t('new_registration.name_of_the_organization')} *`}
          />
        </Grid>

        <Grid xs={12} md={6}>
          {/* <Grid xs={12} md={6} lg={4} xl={3}></Grid> */}
          <Typography variant="subtitle2">
            {t('new_registration.what_type_of_organization')} *
          </Typography>
          <RHFRadioCustomGroup
            column
            name="what_type_of_organization"
            options={institution_types_options}
            guj={locales.currentLang.value === 'guj'}
          />
        </Grid>

        <Grid xs={12} md={6}>
          {/* <Grid xs={12} md={6} lg={4} xl={3}></Grid> */}
          <Typography variant="subtitle2">
            {t('new_registration.detail_of_various_service_work')} *
          </Typography>
          <RHFMultiCustomCheckbox
            column
            name="detail_of_various_service_work"
            options={detail_of_various_service_work_options}
            guj={locales.currentLang.value === 'guj'}
          />
        </Grid>

        <Grid xs={12}>
          <RHFTextField
            name="more_details_about_service"
            label={`${t('new_registration.more_details_about_service')}`}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <RHFAutocomplete
            name="sub_scope_of_the_organization"
            label={`${t('new_registration.sub_scope_of_the_organization')} *`}
            options={jilla_places_options}
            getOptionLabel={(option) =>
              locales.currentLang.value === 'guj' ? option.guj_label : option.label
            }
            isOptionEqualToValue={(option, value) => option.value === value.value}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <RHFTextField
            name="full_address_of_the_institution"
            label={`${t('new_registration.full_address_of_the_institution')} *`}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <RHFTextField
            name="organization_contact"
            label={`${t('new_registration.organization_contact')} *`}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <RHFTextField
            name="email_of_organization"
            label={`${t('new_registration.email_of_organization')} *`}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <RHFTextField
            name="name_of_the_trustee_administrator_of_the_institution"
            label={`${t(
              'new_registration.name_of_the_trustee_administrator_of_the_institution'
            )} *`}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <RHFTextField
            name="their_mobile_number"
            label={`${t('new_registration.their_mobile_number')}`}
          />
        </Grid>

        <Grid xs={12}>
          <Typography variant="subtitle2">
            {t('new_registration.scope_of_the_organization')} *
          </Typography>
          <RHFRadioCustomGroup
            column
            name="scope_of_the_organization"
            options={scope_of_the_organization_options}
            guj={locales.currentLang.value === 'guj'}
          />
        </Grid>

        <Grid xs={12} textAlign="end">
          <LoadingButton
            sx={{ maxWidth: 'fit-content', mr: 2 }}
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {registrationData ? t('update') : t('register')}
          </LoadingButton>

          {registrationData && (
            <Button
              sx={{ maxWidth: 'fit-content' }}
              color="inherit"
              size="large"
              variant="outlined"
              onClick={() => {
                // Reset form and clear registrationData
                methods.reset();
                navigate(paths.hssfPaths.registration.list);
              }}
            >
              {t('cancel')}
            </Button>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  );
}
